"use client";
import { useCohere } from "#/hooks/use-cohere";
import { useLoggedIn } from "#/hooks/use-logged-in";
import { Images } from "#/themes";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { ReactNode, useEffect, useState } from "react";

export default function UnProtectedPage({
  children,
  skipRedirect,
}: {
  children: ReactNode | ReactNode[];
  skipRedirect?: boolean;
}) {
  const isLoggedIn = useLoggedIn();
  const router = useRouter();
  const [isMounted, setIsMounted] = useState(false);
  useCohere();

  useEffect(() => {
    if (isLoggedIn && !skipRedirect) {
      router.replace("/menu");
    }
  }, [isLoggedIn, skipRedirect, router]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <div id="main_nprog" className="overflow-y-scroll flex bg-card">
        <div className="h-[100vh] flex-col flex w-[100vw] flex-1 overflow-hidden">
          <div className="flex flex-row itesm-center justify-center flex-1">
            <div className="flex-1 relative overflow-hidden md:flex">
              <Image
                fill
                alt={`POS`}
                src={Images.landing}
                className="flex flex-1 object-cover"
              />
            </div>
            <div className="flex flex-1 flex-col">{isMounted && children}</div>
          </div>
        </div>
      </div>
    </>
  );
}
