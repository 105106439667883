"use client";

import { ThemeContext } from "#/providers/theme-provider";
import Link from "next/link";
import { useContext, useEffect, useMemo, useState } from "react";

export const SwitchRegionLabel = () => {
  const theme = useContext(ThemeContext);
  const [region, setRegion] = useState("us");

  useEffect(() => {
    const hostname = window.location.hostname;
    if (hostname.startsWith("eu.")) {
      setRegion("eu");
    } else {
      setRegion("us");
    }
  }, []);

  const domain = useMemo(() => {
    const protocol = window.location.protocol;
    const host = window.location.host;

    const envToDomainMap = {
      "streamyourpos.com": "eu.streamorders.com",
      "eu.streamorders.com": "streamyourpos.com",
      "dev-unstable-app.hookedapi.com": "eu.dev-unstable-app.hookedapi.com",
      "eu.dev-unstable-app.hookedapi.com": "dev-unstable-app.hookedapi.com",
      "eu.localhost:3000": "localhost:3000",
      "localhost:3000": "eu.localhost:3000",
    };

    const domain = envToDomainMap[host] || host;
    return `${protocol}//${domain}`;
  }, []);

  const switchMessage = useMemo(() => {
    return region === "us" ? "Switch to EU site?" : "Switch to US site?";
  }, [region]);

  const partnerWithEU = ["Stream"].includes(theme.name);

  if (!partnerWithEU) return <></>;

  return (
    <Link href={domain} className="text-primary text-sm ml-2 mt-1">
      {switchMessage}
    </Link>
  );
};
