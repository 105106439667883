"use client";
import UnProtectedPage from "#/components/layout/unprotected-page";
import { PosAuthView } from "#/components/onboarding/pos-auth-view";
import { SwitchRegionLabel } from "#/components/switch-region-label";

import { useTStream } from "#/hooks/use-t-stream";
import { mutations } from "#/lib/atoms/mutations";
import { Button } from "#/ui/deprecated-buttons/Button";
import { ScrollArea } from "#/ui/scroll-area";
import { Input } from "#/ui/standard-input";
import { useAtom } from "jotai";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useCallback } from "react";
import { useForm } from "react-hook-form";

export default function WelcomePageClient() {
  const { register, handleSubmit } = useForm();
  const { t } = useTStream("common");

  const [{ mutateAsync: trigger, isPending: isMutating }] = useAtom(
    mutations.authExisting,
  );

  const router = useRouter();

  const onUpdate = useCallback(
    async (data: any) => {
      try {
        const exists = await trigger(data);
        const route = exists?._id ? "login" : "register";
        router.push(`/${route}?email=${encodeURIComponent(data.email)}`);
      } catch (error) {
        console.error(error);
      }
    },
    [trigger, router],
  );

  return (
    <UnProtectedPage>
      <ScrollArea className="h-[100vh] mb-5">
        <form
          onSubmit={handleSubmit(onUpdate)}
          className="h-[22rem] m-14 flex flex-col justify-end"
        >
          <p className="text-center self-start text-5xl text-foreground font-500">
            {t("title")}
          </p>
          <SwitchRegionLabel />

          <Input
            title={t("Email")}
            placeholder={t("Email")}
            rootStyles="mt-[4.5rem] mb-4 w-full"
            register={{ ...register("email") }}
          />

          <Button loading={isMutating} text={t("Continue")} />
        </form>
        <div className="flex flex-row w-full my-5 items-center">
          <div className="w-full h-[1px] bg-background justify-center items-center" />
          <p className="text-muted font-400 mx-7">{t("Or")}</p>
          <div className="w-full h-[1px] bg-background justify-center items-center" />
        </div>
        <div className="flex flex-col">
          <PosAuthView />
          <Link
            className="mt-2 w-full text-center text-primary text-sm font-500"
            href="/login"
          >
            {t("Already have an account?")}
          </Link>
        </div>
      </ScrollArea>
    </UnProtectedPage>
  );
}
